<!--
    Created by 程雨喵'mac on 2023/12/13.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
.capital-manger-detail {}
</style>

<template>
  <yg-drawer
    :show-bottom="false"
    width="100%"
    :back-type="1"
    title="详情信息"
    @on-close="_close"
  >
    <div
      slot="content"
      class="capital-manger-detail"
    >
      <div v-permission="'system:finance:order:query'">
        <yg-search-area
          ref="searchArea"
          :show-info="false"
          :value="searchOption"
          @on-search="_clickSearch"
        />
      </div>
      <div class="margin-primary-bottom">
        <yg-setting-area
          :items="settingItems"
          :total="page.total"
          :export-condition="settingExportCondition"
        />
      </div>
      <div v-permission="'system:finance:order:query'">
        <yg-table
          :columns="tableColumns"
          :data="tableData"
          :page="page"
          @on-page-change="onChangePage"
        />
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import { CapitalOrderDetailListModel } from '../model/CapitalOrderDataModel';
import ColumnMixin from '../mixin/detailTableColumn';
import FinanceApiSet from '@/request/api/FinanceApiSet';
export default {
  components: {},
  mixins: [ColumnMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      searchOption: {
        property: BaseSearchModel.initData('物业公司', 'site', 'Input').setDisabled(true).setDefault(this.model.propertyCompanyName),
        community: BaseSearchModel.initData('小区名称', 'site', 'Input').setDisabled(true).setDefault(this.model.communityName),
        time: BaseSearchModel.initData('日期范围', 'time', 'DatePickerRange').setDatePickerElevator(['近7天', '近30天', '近90天', '近180天', '近365天']).setDefault([this.model.orderDate, this.model.orderDate])
      },
      settingItems: [
        BaseSettingModel.initData('导出列表', 'download', '').setExport().setExportReportType(13)
      ],
      settingExportCondition: {},
      page: { current: 1, total: 0 },
      tableData: []
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.page.current = 1;
      this.$refs.searchArea._clickSearch();
    });
  },
  methods: {
    _close () {
      this.$emit('on-close');
    },
    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/
    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = FinanceApiSet.orderSubAccountDetail;
      const s = this.searchOption;
      api.params = {
        propertyCompanyId: this.model.propertyCompanyId || '',
        communityId: this.model.communityId || '',
        timeBegin: s.time.value[0] || '',
        timeEnd: s.time.value[1] || ''
      };
      api.params.pageNo = this.page.current;
      api.params.pageSize = this.$config.pageSize;
      this.settingExportCondition = api.params;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = CapitalOrderDetailListModel.initListWithDataList(resData.rows);
      });
    }
  }
};
</script>
