
export default {
  data () {
    return {
      // 下面数据明细-表头
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('refundOrderDate', '扣回日期', 120).setSpan({}),
        BaseTableColumn.initData('propertyCompanyName', '物业公司名称', 110).setSpan({}),
        BaseTableColumn.initData('communityName', '小区名称', 100).setSpan({}),
        BaseTableColumn.initData('communityCode', '小区编码', 100).setSpan({}),
        BaseTableColumn.initData('orderNo', '充电订单编码', 100).setSpan({}),
        BaseTableColumn.initData('refundTotalMoney', '扣回分账总金额', 100).setSpan({ canPrice: true }),
        BaseTableColumn.initData('payTypeDesc', '扣回科目', 100).setSpan({}),
        BaseTableColumn.initData('', '扣回分账金额', 150).setKeyValueList([
          { label: '服务商:', labelWidth: 70, key: 'returnSpAllocateMoney', attr: { canPrice: true } },
          { label: '代理商:', labelWidth: 70, key: 'returnAgentAllocateMoney', attr: { canPrice: true } },
          { label: '物业公司:', labelWidth: 70, key: 'returnPmAllocateMoney', attr: { canPrice: true } }
        ])
      ]
    };
  }
};
