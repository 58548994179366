import BaseModel from '@/libs/base/dataModel/BaseModel';

export class DeductBackTotalModel extends BaseModel {
  constructor () {
    super();
    this.returnAllocateNum = ''; // 扣回分账订单笔数
    this.returnAllocateMoney = ''; // 扣回分账总总金额
    this.weChatPayMoney = ''; // 扣回微信金额
    this.chargeCoinPayMoney = ''; // 扣回充电金额
    this.weChatReturnSpAllocateMoneiy = ''; // 服务商微信退回支出
    this.chargeCoinReturnSpAllocateMoneiy = ''; // 服务商充电币支出
    this.weChatReturnAgentAllocateMoney = ''; // 代理商微信分账金额
    this.chargeCoinReturnAgentAllocateMoney = ''; // 充电币退回分账金额
    this.weChatReturnPmAllocateMoney = ''; // 物业公司微信退回金额
    this.chargeCoinReturnPmAllocateMoney = ''; // 物业公司充电币退回金额
  }

  static createFakeData (len = 30) {
    return {
      returnAllocateNum: parseFloat((Math.random() * 99999).toFixed(2)),
      returnAllocateMoney: parseFloat((Math.random() * 99999).toFixed(2)),
      weChatPayMoney: parseFloat((Math.random() * 99999).toFixed(2)),
      chargeCoinPayMoney: parseFloat((Math.random() * 99999).toFixed(2)),
      weChatReturnSpAllocateMoneiy: parseFloat((Math.random() * 99999).toFixed(2)),
      chargeCoinReturnSpAllocateMoneiy: parseFloat((Math.random() * 99999).toFixed(2)),
      weChatReturnAgentAllocateMoney: parseFloat((Math.random() * 99999).toFixed(2)),
      chargeCoinReturnAgentAllocateMoney: parseFloat((Math.random() * 99999).toFixed(2)),
      weChatReturnPmAllocateMoney: parseFloat((Math.random() * 99999).toFixed(2)),
      chargeCoinReturnPmAllocateMoney: parseFloat((Math.random() * 99999).toFixed(2))
    };
  }
}

export class DeductBackListModel extends BaseModel {
  constructor () {
    super();
    this.refundOrderDate = ''; // 扣回日期
    this.propertyCompanyName = ''; // 物业公司名称
    this.communityName = ''; // 小区名称
    this.communityCode = ''; // 小区编码
    this.communityId = ''; // 小区Id
    this.orderNo = ''; // 订单编码
    this.refundTotalMoney = ''; // 退回总金额
    this.returnSpAllocateMoney = ''; // 退回服务商分账金额
    this.returnAgentAllocateMoney = ''; // 退回代理商分账金额
    this.returnPmAllocateMoney = ''; // 退回物业公司分账金额
    this.payType = ''; // 科目明细（0-微信 1-充电币）
    this.payTypeDesc = ''; // 科目明细描述
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const payTypeStatus = ['微信', '充电币'];
    for (let i = 0; i < len; i++) {
      const dic = {
        refundOrderDate: '2024-01-01',
        propertyCompanyName: BaseModel.getFakeTitle(10),
        communityName: BaseModel.getFakeTitle(10),
        communityCode: 123,
        communityId: 10,
        orderNo: BaseModel.getFakeNumber(),
        refundTotalMoney: BaseModel.getFakeNumber(),
        returnSpAllocateMoney: BaseModel.getFakeNumber(),
        returnAgentAllocateMoney: BaseModel.getFakeNumber(),
        returnPmAllocateMoney: BaseModel.getFakeNumber(),
        payType: i % 2,
        payTypeDesc: payTypeStatus[i % 2]
      };
      list.push(dic);
    }
    return list;
  }
}
