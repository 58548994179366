
export default {
  data () {
    return {
      // 下面数据明细-表头
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('orderDate', '日期').setSpan({}).setWidth(120),
        BaseTableColumn.initData('propertyCompanyName', '物业公司名称', 110).setSpan({}),
        BaseTableColumn.initData('', '省市区', 140).setKeyValueList([
          { label: '省份：', labelWidth: 70, key: 'provinceName', attr: {} },
          { label: '城市：', labelWidth: 70, key: 'cityName', attr: {} },
          { label: '区县：', labelWidth: 70, key: 'districtName', attr: {} }
        ]),
        BaseTableColumn.initData('communityName', '小区名称').setWidth(100).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('', '小区编码', 200).setKeyValueList([
          { label: '吾行：', labelWidth: 60, key: 'communityCode', attr: { canCopy: true } },
          { label: '物业：', labelWidth: 60, key: 'pcSapCode', attr: { canCopy: true } }
        ]),
        BaseTableColumn.initData('singlePayNum', '订单笔数', 80).setSpan({}).setValueButton([], (row, index) => {
          this.dialog.detailModel = row;
        }),
        BaseTableColumn.initData('area', '充电订单').setKeyValueList([
          { label: '订单支付总金额:', labelWidth: 110, key: 'singlePayTotalAccount', attr: { canPrice: true } },
          { label: '总退款金额:', labelWidth: 110, key: 'singleRefundTotalAccount', attr: { canPrice: true } },
          { label: '实收总金额:', labelWidth: 110, key: 'singlePayFundsReceived', attr: { canPrice: true } }
        ]).setWidth(220),
        BaseTableColumn.initData('area', '服务费支出').setKeyValueList([
          { label: '支付渠道:', labelWidth: 60, key: 'payChannelServiceDisburse', attr: { canPrice: true } },
          { label: '系统服务:', labelWidth: 60, key: 'sysChannelServiceDisburse', attr: { canPrice: true } }
        ]).setWidth(160),
        BaseTableColumn.initData('giftAmount', '扣除赠送金额', 90).setSpan({ className: 'color-text-strong', canPrice: true }),
        BaseTableColumn.initData('', '可分账金额').setKeyValueList([
          { label: '微信：', labelWidth: 60, key: 'canSubAccount', attr: { canPrice: true } },
          { label: '充电币：', labelWidth: 60, key: 'chargeCoinCanSubAccount', attr: {} }
        ]).setWidth(120),
        BaseTableColumn.initData('area', '分账金额(微信)').setKeyValueList([
          { label: '服务商:', labelWidth: 70, key: 'serviceProviderSubAccount', attr: { canPrice: true } },
          { label: '代理商:', labelWidth: 70, key: 'agentSubAccount', attr: { canPrice: true } },
          { label: '物业公司:', labelWidth: 70, key: 'propertyCompanySubAccount', attr: { canPrice: true } }
        ]).setWidth(150),
        BaseTableColumn.initData('area', '分账金额(充电币)').setKeyValueList([
          { label: '服务商:', labelWidth: 70, key: 'chargeCoinServiceProviderSubAccount', attr: {} },
          { label: '代理商:', labelWidth: 70, key: 'chargeCoinAgentSubAccount', attr: {} },
          { label: '物业公司:', labelWidth: 70, key: 'chargeCoinPropertyCompanySubAccount', attr: {} }
        ]).setWidth(150)
      ]
    };
  }
};
