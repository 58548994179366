<!--
    Created by 王丽莎 on 2024/08/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称:财务管理-分账订单管理-扣回分账管理
-->
<style lang="less">
#deduct-back--manager {
  min-width: 700px;
  .card-outer {
    margin: 0 -15px;
    .total-data-box {
      display: flex;
      //flex-wrap: wrap;
      border-radius: @borderRadiusMin;
      overflow: auto;
      border: 1px solid @lineColorNormal;
      border-right: 0;
      .total-data-item {
        flex: 1;
        min-width: 100px;
        border-right: 1px solid @lineColorNormal;
        &:nth-of-type(2n) {
          .total-data-label {
            //background-color: transparent;
          }
        }
        .total-data-label {
          padding: 8px 5px;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          white-space: pre-line;
          color: @textColorLight;
          background-color: @backColorNormal;
        }
        .total-data-value {
          .display-flex();
          line-height: 50px !important;
          font-weight: 600;
          font-size: 13px;
        }
        .sa-price-total-data-value {
          height: 50px !important;
          padding: 6px 0;
          box-sizing: border-box;
          width: 100%;
          overflow: hidden;
        }
      }
    }
  }
  .color-text-strong { color: @adornColor; font-weight: 600 }
  .color-text-finish { color: @finishColor; }
  .color-text-primary { color: @textColorNormal; }
}
</style>

<template>
  <yg-drawer
    :show-bottom="false"
    width="100%"
    title="扣回分账管理"
    @on-close="_close"
  >
    <div
      id="deduct-back--manager"
      slot="content"
    >
      <div
        v-permission="'system:admin:financeManage:returnAccountSummary'"
        class="card-outer"
      >
        <yg-card
          title="数据汇总"
        >
          <div slot="content">
            <div class="total-data-box">
              <div
                v-for="(item, i) in totalItems"
                :key="i"
                class="total-data-item"
                :style="'min-width: ' + item.itemWidth + 'px'"
              >
                <div class="total-data-label">
                  {{ item.label }}
                </div>
                <div
                  v-if="item.isKeyValue"
                  class="sa-price-total-data-value"
                >
                  <yg-attribute-key-value
                    v-for="(re, ind) in item.keyValueData"
                    :key="ind"
                    :label="re.label"
                    :label-width="80"
                    :value="pageData[re.value] + ''"
                    :can-price="re.isPrice"
                  />
                </div>
                <yg-attribute-span
                  v-else
                  :can-price="item.priceText"
                  :text="pageData[item.key] + ''"
                  class="total-data-value"
                  :class="`${'color-text-' + item.textColor}`"
                />
              </div>
            </div>
          </div>
        </yg-card>
      </div>
      <div
        v-permission="'system:admin:financeManage:refundAccountDetail'"
        class="card-outer"
        style="margin-top: -5px"
      >
        <yg-card
          title="数据明细"
          style="padding-bottom: 0"
        >
          <div slot="content">
            <div class="margin-primary-bottom">
              <yg-setting-area
                :items="settingItems"
                :total="page.total"
                :export-condition="settingExportCondition"
                @on-click="_clickSetting"
              />
            </div>
            <yg-table
              :columns="tableColumns"
              :data="tableData"
              :max-height="9999"
              :page="page"
              @on-page-change="onChangePage"
            />
          </div>
        </yg-card>
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import { DeductBackTotalModel, DeductBackListModel } from '../model/DeductBackDataModel';
import FinanceApiSet from '@/request/api/FinanceApiSet';
import ColumnMixin from '../mixin/deductBackTableColumn';
export default {
  components: {},
  mixins: [ColumnMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 搜索条件数据
    searchOptionsData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      totalItems: [
        { key: 'returnAllocateNum', label: '扣回分账\n订单数', textColor: 'strong', priceText: false, isKeyValue: false },
        { key: 'returnAllocateMoney', label: '扣回分账\n总金额', textColor: 'strong', priceText: true, isKeyValue: false },
        {
          key: '',
          label: '扣回\n科目明细',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 160,
          keyValueData: [
            { label: '微信:', value: 'weChatPayMoney', isPrice: true },
            { label: '充电币:', value: 'chargeCoinPayMoney' }
          ]
        },
        {
          key: '',
          label: '服务商\n扣回分账金额',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 160,
          keyValueData: [
            { label: '微信:', value: 'weChatReturnSpAllocateMoneiy', isPrice: true },
            { label: '充电币:', value: 'chargeCoinReturnSpAllocateMoneiy' }
          ]
        },
        {
          key: '',
          label: '代理商\n扣回分账金额',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 160,
          keyValueData: [
            { label: '微信:', value: 'weChatReturnAgentAllocateMoney', isPrice: true },
            { label: '充电币:', value: 'chargeCoinReturnAgentAllocateMoney' }
          ]
        },
        {
          key: '',
          label: '物业公司\n扣回分账金额',
          textColor: 'primary',
          priceText: false,
          isKeyValue: true,
          itemWidth: 160,
          keyValueData: [
            { label: '微信:', value: 'weChatReturnPmAllocateMoney', isPrice: true },
            { label: '充电币:', value: 'chargeCoinReturnPmAllocateMoney' }
          ]
        }
      ],
      settingItems: [
        BaseSettingModel.initData('刷新数据', 'refresh', 'md-refresh-circle').setPermission('system:admin:financeManage:refundAccountDetail'),
        BaseSettingModel.initData('导出列表', 'download', 'md-cloud-download').setExport().setExportReportType(28).setPermission('system:admin:financeManage:refundAccountDetail:export')
      ],
      settingExportCondition: {},
      page: { current: 1, total: 0 },
      tableData: [],
      pageData: {}
    };
  },
  created () {
    console.log(this.searchOptionsData);
    this.requestTotalData();
    this.requestData();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSetting (key) {
      console.log(key);
      if (key === 'refresh') {
        console.log(this.searchOptionsData);
        this.page.current = 1;
        this.requestData();
        this.requestTotalData();
      }
    },
    _close () {
      this.$emit('on-close');
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestTotalData () {
      const api = FinanceApiSet.returnAccountSummary;
      api.params = this.searchOptionsData;
      if (api.params.pageNo) {
        delete api.params.pageNo;
      }
      if (api.params.pageSize) {
        delete api.params.pageSize;
      }
      this.$http(api).then(res => {
        const pageData = res.data;
        this.pageData = DeductBackTotalModel.initModelWithData(pageData);
      });
      // const resData = DeductBackTotalModel.createFakeData();
      // this.pageData = DeductBackTotalModel.initModelWithData(resData);
    },
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = FinanceApiSet.refundAccountDetail;
      api.params = this.searchOptionsData;
      api.params.pageNo = this.page.current;
      api.params.pageSize = this.$config.pageSize;
      this.settingExportCondition = api.params;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = DeductBackListModel.initListWithDataList(resData.rows);
      });
      // const resList = DeductBackListModel.createFakeDataList(10);
      // this.page.total = resList.length;
      // this.tableData = DeductBackListModel.initListWithDataList(resList);
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
